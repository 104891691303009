import { getCleanedString } from '@canalplus/mycanal-commons';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

// eslint-disable-next-line react/prefer-stateless-function
export class SchemaArticle extends PureComponent {
  render() {
    const { informations } = this.props;
    const { title, abstract, mainMedia, author, publishedAt, updatedAt } = informations;

    const formatPublishedAt = new Date(publishedAt).toISOString();
    const formatUpdatedAt = new Date(updatedAt).toISOString();

    const headline = getCleanedString(title);
    const description = abstract ? getCleanedString(abstract) : null;

    const DATA = `{
    "@context": "http://schema.org/",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://google.com/article"
    },
    "headline": "${headline}",
    "image": "${mainMedia && mainMedia.URLImage}",
    "datePublished": "${formatPublishedAt}", 
    "dateModified": "${formatUpdatedAt}",
    ${
      author
        ? `"author": {
      "@type": "Person",
      "name": "${author.name}"
    },`
        : ''
    }
    "publisher": {
      "@type": "Organization",
      "name": "myCANAL",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.canalplus.com/widetile.png"
      }
    }${description ? `,\n "description": "${description}"` : ''}
  }`;

    // eslint-disable-next-line react/no-danger
    return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: DATA }} />;
  }
}

SchemaArticle.propTypes = {
  informations: PropTypes.object.isRequired,
};
