import { TitleDisplayMode } from '@canalplus/sdk-hodor';
import classNames from 'classnames';
import { Children, cloneElement } from 'react';
import { TemplateHeader } from '../TemplateHeader/TemplateHeader';
import styles from './ContentRow.module.css';
import ContentRowHorizontalScroller from './ContentRowHorizontalScroller';
import { ContentRowProps } from './types';

/**
 * Displays contents in a scrollable row.\
 * If `title` in `header` prop is, it renders also a header.
 */
export function ContentRow({
  children,
  defaultItemOffset = -1,
  getNextContent,
  header,
  imageSize = 'normal',
  isFromDetail = false,
  isFromImmersive = false,
  isFromShowcase = false,
  isFromCreativeMedia = false,
  isMobile = false,
  isOrderedList = false,
  isTvDevice = false,
  labels,
  Linker,
  ratio,
  scrollDuration = 200,
  showControls = true,
  theme,
  titleDisplayMode = TitleDisplayMode.All,
}: ContentRowProps): JSX.Element | null {
  if (!children) {
    return null;
  }

  return (
    <div className={styles.contentRow}>
      {header?.title && (
        <div
          className={classNames(styles.contentRow__header, {
            [styles['contentRow__header--detailV5']]: isFromDetail,
            [styles['contentRow__header--showcase']]: isFromShowcase,
          })}
        >
          <TemplateHeader
            {...header}
            displayBigTitle={false}
            isFromDetail={isFromDetail}
            isFromShowcase={isFromShowcase}
            isFromCreativeMedia={isFromCreativeMedia}
            isMobile={isMobile}
            theme={theme}
            isTvDevice={isTvDevice}
            Linker={Linker}
          />
        </div>
      )}

      <div>
        <ContentRowHorizontalScroller
          isFromDetail={isFromDetail}
          isFromImmersive={isFromImmersive}
          arrowPositionAuto={isFromCreativeMedia}
          ratio={ratio}
          imageSize={imageSize}
          getNextContent={getNextContent}
          defaultItemOffset={defaultItemOffset}
          labels={labels}
          showControls={showControls}
          titleDisplayMode={titleDisplayMode}
          scrollDuration={scrollDuration}
          isOrderedList={isOrderedList}
        >
          {Children.map(children, (child) => cloneElement(child))}
        </ContentRowHorizontalScroller>
      </div>
    </div>
  );
}
